// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--36966";
export var circleTes = "Testimonials2-module--circleTes--a6136";
export var content = "Testimonials2-module--content--6690f";
export var des = "Testimonials2-module--des--9b83f";
export var iconContainer = "Testimonials2-module--iconContainer--a37ae";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--27c83";
export var personBg = "Testimonials2-module--personBg--f22fc";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--d12c3";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--dac5b";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--87bf7";
export var row = "Testimonials2-module--row--f7d91";
export var section = "Testimonials2-module--section--5ea34";
export var tesBtn = "Testimonials2-module--tesBtn--28557";
export var tesDots = "Testimonials2-module--tesDots--5447a";
export var tesRec = "Testimonials2-module--tesRec--ac0ff";
export var testimonials = "Testimonials2-module--testimonials--38156";